import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Grid, Box} from "@mui/material";
import Paths from '../utils/paths';
import * as requests from '../utils/requests';
import { preventDefault, handleEvent, useEvent, fireEvent } from "../utils/events";
import ProjectCard from '../widgets/ProjectCard';
import RenameDialog from "../dialog/RenameDialog";
import DeleteDialog from "../dialog/DeleteDialog";

import { CreateNewProjectButton } from "../widgets/CreateNewProjectButton";
import AppWrapper from "../widgets/AppWrapper";
import { isDeveloper, isRerUser, user_get } from "../utils/http";
import { saveAs } from "file-saver";
import { justfname } from "../utils/filesystem";
import { useInterval } from "../utils/useinterval";
import { ConfirmDialogProvider } from "react-mui-confirm";
import { len } from "../utils/strings";
import AlertDialog from "../dialog/AlertDialog";
import TagnameDialog from "../dialog/TagnameDialog";
import ToggleChip from "../widgets/mui/ToggleChip";
import RemoveTagDialog from "../dialog/RemoveTagDialog";
//import { useIsAuthenticated } from "@azure/msal-react";

export const MyProjectsPage = () => {


    const [projects, setProjects] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [reload, setReload] = useState(false)
    const [search, setSearch] = useState([])
    const [filters, setFilters] = useState([]) //eslint-disable-line
    //const navigate = useNavigate()
    
    //const isAuthenticated = useIsAuthenticated()
   
    //eslint-disable-next-line
    const handleFilterChange = (event, newtag, selected) => {

      let tags = []
      if (newtag === "*"){
        tags = []
        setFilters([])
      }else if (selected) {
        tags = [...search, newtag]
      } else {
        tags = search.filter(tag => tag !== newtag)
      }
      setSearch(tags)
    }
   
    useEffect(() => {
      
        sessionStorage.removeItem("projectname", "")
        sessionStorage.removeItem("linkname", "")

        if (isRerUser() && len(contacts) === 0){
          requests.list_contacts("RER").then(items => {setContacts(items)})
        }
    
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEvent("project:download", (event) => {
      user_get("/api/project",{name:event.detail}).then(response => {
        if (response.data.status === "success") {
          let filezip = response.data.name
          saveAs(filezip, justfname(filezip))
        }
      })
    })

    //Reload the page every 10 seconds
    useInterval(() => {fireEvent("event:reload")}, 10000)

    useEffect(() => {

      const fetchProjects = () => requests.listProjects(search)

      fetchProjects().then((response) => {
        if (response && response.data && response.data.data) {
          setProjects(response.data.data)
          setFilters(response.data.filters)
        }
      })
    
    },[reload, search])

    useEffect(
      ()=>handleEvent("event:reload", ()=>{console.log("[RELOAD]");setReload(!reload)})
    ,[reload])// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <ConfirmDialogProvider>
          <AppWrapper>

                <Box container="main" sx={{height:"100%", width:"90%", float:"right"}} className="safer-myProjs">            
                
                  <Grid container 
                        sx={{display:"flex"}}
                        columns={{xs:3, sm: 4, md:6, lg:9, xl:12}}
                        width="80%"
                        spacing={2} 
                        direction="row"
                        justifyContent="left"
                        onContextMenu={preventDefault}
                        >

                    <Grid item xs={3}> 
                      <h1>MY PROJECTS</h1>
                    </Grid>

                    {/* Search by tags */}
                    <Grid item xs={9}>
                      {isDeveloper() && filters.map( (item, i) => 
                        <ToggleChip key={`filter-${i}`} label={`${item.name} (${item.count})`} value={item.name} onClick={handleFilterChange}/>
                      )}
                    </Grid> 
                    
                  
                    {/* the first card is a button */}
                    <Grid item key={0} xs={3} sm={4} md={3} lg={3} xl={3}>
                        <CreateNewProjectButton component={Link}
                          to = {Paths.NEW_PROJECT}
                          //onClick={() => {navigate(Paths.NEW_PROJECT)}}
                        />      
                    </Grid>
            
            
                    { // for each case study available for the user, create a card
                      projects.map( ( item, i) => 
                        <Grid item key={i+1} xs={3} sm={4} md={3} lg={3} xl={3}>
                          <ProjectCard key={`Card-${i}`} id={`Card-${i}`} contacts={contacts} {...item} />
                        </Grid>
                      )
                    }
              </Grid>
              </Box>
            <AlertDialog />
            <RenameDialog />
            <TagnameDialog />
            <RemoveTagDialog />
            <DeleteDialog />  

          </AppWrapper>
        </ConfirmDialogProvider>
      );
}