import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import * as requests from '../utils/requests';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import { fireEvent } from '../utils/events';
import { duplicateProject } from '../utils/requests';
import { 
  DeleteForever, 
  DriveFileRenameOutline, 
  ContentCopy, 
  MoreVert, 
  FolderZip,
  Share,
  Send,
  Person,
  Group,
  Sell as SellIcon,
} from "@mui/icons-material";
import { LoadingIcon, SaferPlacesIcon } from '../icons/MapIcons';
import { isDeveloper, isRerUser } from '../utils/http';
import { Accordion, AccordionDetails, AccordionSummary, capitalize, TextField } from '@mui/material';
import { MenuItemOpt } from './mui/MenuItemOpt';
import { isValidEmail } from '../utils/regexp';
import { len } from '../utils/strings';



export default function ContextMenu(props) {
  
  const projectname = props.linkname
  const shared = props.shared

  //useMemo to avoid the re-rendering of the contacts
  const contacts = useMemo(() => props.contacts||[], [props.contacts])
  const [loading, setLoading] = useState(true)

  const [anchorMain, setAnchorMain] = useState(null);
  const open = Boolean(anchorMain);

  const [anchorShare, setAnchorShare] = useState(null);
  const openShare = Boolean(anchorShare);

  const [shareduser, setSharedUser] = useState("")

  const handleMainMenuOpen = (event) => {
    event.preventDefault()
    setAnchorMain(event.currentTarget);
  }

  const handleShareMenuOpen = (event) => {
    event.preventDefault()
    setAnchorShare(event.currentTarget);
  }

  const handleDuplicate = () => {
    duplicateProject(projectname)
      .then(() => fireEvent("event:reload"))              // calls the event -> directly apply the effect and reload the list
    handleMainMenuClose()
  }

  const handleSharedUser = (event) => {
    setSharedUser(event.target.value)
  }

  const stopPropagation = (event) => {
    event.stopPropagation()
  }

  const handleShare = () => {

    if (isValidEmail(shareduser)){
      const params = {name: projectname, sharewith: shareduser}
      requests.shareProject(params).then(onProjectShared)
      handleShareMenuClose()
      handleMainMenuClose()
    }
  }

  const onProjectShared = (response) => {
    
    response.data.title = capitalize(response.data.status)
    response.data.severity = response.data.status 
    fireEvent("event:alert", response.data)
  }

  const handleNewTag = () => {
    // a random word
    const tagname = Math.random().toString(36).substring(7)
    fireEvent("project:newtag", {"projectname":projectname, "tagname":tagname})
    handleMainMenuClose()
  }

  const handleDownload = () => {
    fireEvent("project:download", projectname) 
    handleMainMenuClose()
  }

  const handleRename = () => {
    fireEvent("event:rename", projectname) // calls the event -> show the dialog
    handleMainMenuClose()
  }

  const handleDelete = () => {
    fireEvent("event:delete", projectname) // calls the event -> show the dialog
    handleMainMenuClose()
  }

  const handleMainMenuClose = () => {
    setAnchorMain(null);
  };

  const handleShareMenuClose = () => {
    setAnchorShare(null);
  }

  useEffect(() => {

    if (len(contacts) === 0){
      setLoading(true)
    }else{
      setLoading(false)
    }

  },[contacts])

  return (
    <div onContextMenu={handleMainMenuOpen}>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMainMenuOpen}
        onContextMenu={handleMainMenuOpen}
      >
      <MoreVert /> 
      </IconButton>
      <Menu
        id="main-menu"
        anchorEl={anchorMain}
        open={open}
        onClose={handleMainMenuClose}
      >
        <MenuList>
            <MenuItemOpt
              hidden = {shared}
              action = {handleRename}
              icon = {<DriveFileRenameOutline fontSize="small" />}
              text = "Rename"
            />

            <MenuItemOpt 
              //hidden = {shared}
              //disabled = {!isDeveloper()}
              action = {handleDuplicate}
              icon = {<ContentCopy fontSize="small" />}
              text = "Duplicate"
            />

            <MenuItemOpt
              //hidden = {!isDeveloper()}
              //disabled = {!isDeveloper()}
              action = {handleShareMenuOpen}
              icon = {<Share fontSize="small" />}
              text = "Share with"
            />

            <Menu
              id="share-menu"
              anchorEl={anchorShare}
              open={openShare}
              onClose={handleShareMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem>
                {/* insert a textfield editable */}
     
                <TextField
                  value = {shareduser}
                  onKeyDown={stopPropagation}
                  onChange={handleSharedUser}
                  autoFocus
                  type="email"
                  fullWidth
                  variant="standard">
                </TextField>


                <ListItemIcon>
                  <IconButton
                    onClick={handleShare}>
                    <Send color={isValidEmail(shareduser)?"primary":"gray"}/>
                  </IconButton>
                </ListItemIcon>
              </MenuItem>

              {isRerUser()? 
              <Accordion>
                <AccordionSummary>
                {loading?<LoadingIcon/>:<Group/>} Regione Emilia Romagna
                </AccordionSummary>
                <AccordionDetails>  
                  { contacts.map((contact) => {
                    return (
                      <MenuItem key={contact.id} onClick={() => {setSharedUser(contact.email_address)}}>  
                        <Person/>{contact.full_name}
                      </MenuItem>
                    )
                  })}
                </AccordionDetails>
              </Accordion>:null
              }

              <Accordion>
                <AccordionSummary>
                  <SaferPlacesIcon/> SaferPlaces Team
                </AccordionSummary>
                <AccordionDetails>  
                  <MenuItem onClick={() => {setSharedUser("platform@saferplaces.co")}}>  
                    <SaferPlacesIcon/>Share for support
                  </MenuItem>
                </AccordionDetails>
              </Accordion>

            </Menu>

            <MenuItemOpt 
              hidden = {!isDeveloper()}
              //disabled = {!isDeveloper()}
              action = {handleNewTag}
              icon = {<SellIcon fontSize="small" />}
              text = "Add a tag"
            />

            <MenuItemOpt 
              hidden = {!isDeveloper()}
              action = {handleDownload}
              icon = {<FolderZip fontSize="small" />}
              text = "Download"
            />


            <Divider />

            <MenuItemOpt 
              //hidden = {shared}
              action = {handleDelete}
              icon = {<DeleteForever fontSize="small" />}
              text = "Delete"
            />

            </MenuList>
        </Menu>          
    </div>
  );
}
