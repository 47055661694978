import { useContext, useEffect, useState } from "react";
import { QgisContext, QgisProject } from "@SaferPlaces2023/safer-map";
import { Alert, Button,  FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import { MessageBoxShow } from "../../widgets/StatusMessageBox";
import SaveIcon from '@mui/icons-material/Save';
import DoneIcon from '@mui/icons-material/Done';
import {  closedOptions, readOnlyOptions } from "./DefaultProjectSettings";
import { saveProject } from "../../utils/requests";
import { AppContext } from "../../../App";

const projectExists = (project) => {
    return project && Object.keys(project).length !== 0
}

/**
 * According to the type of the setting, return the corresponding FormControl component (TextField, Checkbox, etc.)
 * @param {*} type 
 */
const OptionControl = ({type, value, name, onChange}) => {
    
    const disabled = readOnlyOptions.includes(name)
    return <> 
    {
    // if the type is boolean, return a switch
        type === "boolean" ? 
            <FormControl key={name}>
                <FormControlLabel
                    control={<Switch checked={value} disabled={disabled}/>}
                    onChange={(e) => onChange(name, e.target.checked)}
                    label={name} />
            </FormControl>


    // if the type is string and the name is in the closed options, return a select
        : type === "string" && Object.keys(closedOptions).includes(name) ?
            <FormControl key={name}>
                <InputLabel>{name}</InputLabel>
                <Select
                    disabled = {disabled}
                    sx={{ width: 100 }}
                    size="small"
                    variant="standard"
                    value={closedOptions[name].includes(value) ? value : ""} // if the current value is not in the options, set it to ""
                    label={name}
                    onChange={(e) => onChange(name, e.target.value)}
                >
                    {   
                        closedOptions[name].map((opt) => {
                            return <MenuItem key={opt} value={opt}>{opt}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>       


    // if the type is int, return a numeric textfield
        : type === "int" ? 
            <FormControl key={name}>
                <TextField
                    disabled = {disabled}
                    sx={{ width: 50 }}
                    size="small"
                    id={name}
                    label={name}
                    variant="standard"
                    type={"number"}
                    value={value}
                    onChange={(e) => onChange(name, e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
        </FormControl>
        
        
    // in the other cases, return a textfield
    :   <FormControl key={name}>
            <TextField
                disabled = {disabled}
                sx={{ width: 100 }}
                size="small"
                id={name}
                label={name}
                value={value}
                onChange={(e) => onChange(name, e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </FormControl>

    }</>
}

/**
 * 
 * @param {*} autoSave  
 * @returns 
 */
const ProjectSettingsPanel = ({autoSave = false}) => {

    /** When the project in the context is not set (outside the ProjectPage) we cannot instantiate [project, setProject] as usual (gives error)*/
    let context = useContext(QgisContext) // [project, setProject]
    const { linkname} = useContext(AppContext)
    if (!context.project && context[0]) {
        context = {project: context[0], setProject: context[1]}
    }

    const Q = projectExists(context.project) ? new QgisProject(context.project, context.setProject) : null  // in that case, Q is null
    const [settings, setSettings] = useState(undefined)
    const [hasChanged, setHasChanged] = useState(false)

    useEffect(() => {
        if (Q && (!settings || !settings.length)) {
            setSettings(Q.getProjectSettings())
        }
    }, [settings]) // eslint-disable-line 


    const handleChangeSetting = (key, value) => {
        setHasChanged(true)
        const newSettings = settings.map((setting) => {
            if (setting["@name"] === key) {
                setting["@value"] = value
            }
            return setting
        })
        setSettings(...newSettings)

        if (autoSave) {
            handleSaveProject()
        }
    }


    const handleSaveProject = () => {
        
        settings.forEach((setting) => {
            Q.setProjectSetting(setting["@name"], setting["@value"])
        })
        
        // Save settings [SAVE]
        saveProject({xml: Q.ExportToXml(), name:linkname, overwrite: true}).then(() => { MessageBoxShow("Settings saved")})
        setHasChanged(false)
    }


    /** settings is a list of dictionaries. Each dictionary contains a type field. 
    Sort the list by type in this order: boolean, string, int */
    const sortByType = (settings) => {
        return settings.sort((a, b) => {
            if (a["@type"] === "boolean") {
                return -1
            } else if (a["@type"] === "string" && b["@type"] === "int") {
                return -1
            } else {
                return 1
            }
        })
    }


    return projectExists(context.project) ? 

            <>
                <Stack spacing={2} margin={2}>
                    {   
                        Q.getProjectSettings().length ?
                        sortByType(Q.getProjectSettings()).map((setting, i) => {
                            
                            return  <OptionControl 
                                        key={i}
                                        name={setting["@name"]} 
                                        value={setting["@value"]} 
                                        type={setting["@type"]}
                                        onChange={handleChangeSetting}
                                    />
                        })
                        : 
                        <Typography variant="body2" color="text.secondary">
                            no settings found
                        </Typography>
                    }

                    { 
                        !autoSave? 
                            <Button 
                                onClick={handleSaveProject} 
                                disabled={!hasChanged}
                                variant="outlined"
                                color="primary"
                                size="small"
                                startIcon={hasChanged ? <SaveIcon /> : <DoneIcon />}
                            >
                                {hasChanged ? "Save setting" : "Saved"}
                            </Button>
                        : null
                    }

                
                </Stack>
            </>
            : 
            <>
                <Alert severity="warning">
                    <strong>No project loaded!</strong> <br/>
                    Open a project to see its settings
                </Alert>
            </>
}
export default ProjectSettingsPanel;